@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Area 
{
	--animationDelay: 3s;

	position: absolute;
	overflow: hidden;
	z-index: 1;
	height: 100%;
	width: 100%;
	top: 0em;
	bottom: 0em;
	left: 0em;
	right: 0em;

	.Area-backgroundWrapper {
		position: relative;
		height: 100%;
		width: 100%;

		// --------------- Area1 --------------- //
		.Area-area1BackgroundSvg 
		{
			position: relative;
			height: 100%;
			width: 100%;
			
			#C3,
			#C2,
			#C1,
			#B3,
			#B2,
			#B1-2,
			#A3,
			#A2,
			#A1 {
				display: none;
			}

			// --------------- Basic Positions --------------- //
			&.center {
				@include flex('center', 'center');
				@include scale(75%);
			}
	
			&.bottom {
				@include scale(130%);
				@include flex('center', 'flex-end');
				bottom: -25em;
			}
			&.side {
				@include scale(60%);
				@include flex('center', 'flex-end');
				right: -20em;
			}
			&.large-side {
				@include scale(80%);
				@include flex('center', 'flex-end');
				right: -10em;
			}
			&.allAreas {
				width: 35em;
				height: auto;
				left: calc(50% - 48.5em);
			}

			///// ACTIONS /////
			// Vildsvin
			&.area1-Category1-Action1-animated
			{
				#A1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category1-Action1
			{
				#A1 {
					display: block;
				}
			}
			// Mountainbike-sti
			&.area1-Category1-Action2-animated
			{
				#A2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category1-Action2
			{
				#A2 {
					display: block;
				}
			}
			// Uberørt skov
			&.area1-Category1-Action3-animated
			{
				#A3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category1-Action3
			{
				#A3 {
					display: block;
				}
			}
			
			// Fjern søen og lav enge
			&.area1-Category2-Action1-animated
			{
				#B1-2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Lake {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category2-Action1
			{
				#B1-2 {
					display: block;
				}
				#Lake {
					@include opacity(0);
				}
			}
			// Fjern søen og lav marker
			&.area1-Category2-Action2-animated
			{
				#B2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Lake {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category2-Action2
			{
				#B2 {
					display: block;
				}
				#Lake {
					@include opacity(0);
				}
			}
			// Lav badesø
			&.area1-Category2-Action3-animated
			{
				#B3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category2-Action3
			{
				#B3 {
					display: block;
				}
			}
			
			// Tillad adgang
			&.area1-Category3-Action1-animated
			{
				#C1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category3-Action1
			{
				#C1 {
					display: block;
				}
			}
			// Byg solcelleanlæg
			&.area1-Category3-Action2-animated
			{
				#C2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Overdrev {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category3-Action2
			{
				#C2 {
					display: block;
				}
				#Overdrev {
					@include opacity(0);
				}
			}
			// Etabler flere levende hegn og lunde
			&.area1-Category3-Action3-animated
			{
				#C3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area1-Category3-Action3
			{
				#C3 {
					display: block;
				}
			}
		}

		// --------------- Area2  --------------- //
		.Area-area2BackgroundSvg 
		{
			position: relative;
			width: 100%;
			height: 100%;

			#C3,
			#C2,
			#C1,
			#B3,
			#B2,
			#B1,
			#A3,
			#A2,
			#A1 {
				display: none;
			}
		
			// --------------- Basic Positions --------------- //
			&.center {
				height: 100%;
				width: 100%;
				@include scale(60%);
			}
			&.bottom {
				height: auto;
				@include scale(130%);
				@include translate(-22em, 3em);
			}
			&.side {
				@include scale(60%);
				@include flex('center', 'flex-end');
				right: -20em;
			}
			&.large-side {
				@include scale(80%);
				@include flex('center', 'flex-end');
				right: -10em;
			}
			&.allAreas {
				width: 33em;
				height: auto;
				left: calc(50% - 16.5em);
				bottom: -0.25em;
			}

			///// ACTIONS /////
			// Øget vedligeholdelse
			&.area2-Category1-Action1-animated
			{
				#B1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Flod {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category1-Action1
			{
				#B1 {
					display: block;
				}
				#Flod {
					display: none;
				}
			}
			// Genslynge åen
			&.area2-Category1-Action2-animated
			{
				#B2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Flod {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category1-Action2
			{
				#B2 {
					display: block;
				}
				#Flod {
					display: none;
				}
			}
			// Bedre rekreative muligheder
			&.area2-Category1-Action3-animated
			{
				#B3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category1-Action3
			{
				#B3 {
					display: block;
				}
			}
			
			// Udtag lavbundsjorder
			&.area2-Category2-Action1-animated
			{
				#A1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Marker_front {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category2-Action1
			{
				#A1 {
					display: block;
				}
				#Marker_front {
					display: none;
				}
			}
			// Produktion af biomasse
			&.area2-Category2-Action2-animated
			{
				#A2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Marker_front {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category2-Action2
			{
				#A2 {
					display: block;
				}
				#Marker_front {
					display: none;
				}
			}
			// Udsæt dyr
			&.area2-Category2-Action3-animated
			{
				#A3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Marker_front {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category2-Action3
			{
				#A3 {
					display: block;
				}
				#Marker_front {
					display: none;
				}
			}
			
			// Grusgravning
			&.area2-Category3-Action1-animated
			{
				#C1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Bakker,
				#Marker_hills {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category3-Action1
			{
				#C1 {
					display: block;
				}
				#Bakker,
				#Hills,
				#Marker_hills {
					display: none;
				}
			}
			// Klimaskov
			&.area2-Category3-Action2-animated
			{
				#C2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Hills,
				#Marker_hills {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category3-Action2
			{
				#C2 {
					display: block;
				}
				#Hills,
				#Marker_hills {
					display: none;
				}
			}
			// Intensiveret dyrkning
			&.area2-Category3-Action3-animated
			{
				#C3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area2-Category3-Action3
			{
				#C3 {
					display: block;
				}
			}
		}

		// --------------- Area3  --------------- //
		.Area-area3BackgroundSvg 
		{
			position: relative;
			width: 100%;
			height: 100%;

			#Gate,
			#C3,
			#C2,
			#C1,
			#B3,
			#B2,
			#B1,
			#A3,
			#A2,
			#A1 {
				display: none;
			}
		
			// --------------- Basic Positions --------------- //
			&.center {
				width: 100%;
				@include scale(65%);
			}

			&.bottom {
				height: auto;
				width: 140%;
				@include translate(-22em, 3em);
			}
			&.side {
				@include flex('center', 'flex-end');
				right: -20em;
			}
			&.large-side {
				@include scale(105%);
				@include flex('center', 'flex-end');
				right: -10em;
			}
			&.allAreas {
				width: 26em;
				height: auto;
				left: calc(50% + 13em);
				bottom: 0.5em;
			}

			///// ACTIONS /////
			// Fjern møllen
			&.area3-Category1-Action1-animated
			{
				#A1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#mill {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category1-Action1
			{
				#A1 {
					display: block;
				}
				#mill {
					display: none;
				}
			}
			// Oplevelsescenter
			&.area3-Category1-Action2-animated
			{
				#A2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#mill {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category1-Action2
			{
				#A2 {
					display: block;
				}
				#mill {
					display: none;
				}
			}
			// Sluse
			&.area3-Category1-Action3-animated
			{
				#A3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category1-Action3
			{
				#A3 {
					display: block;
				}
			}
			
			// Afgræsning og naturpleje
			&.area3-Category2-Action1-animated
			{
				#Gate,
				#B1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Sheep {
					display: none;
				}
			}
			&.area3-Category2-Action1
			{
				#Gate,
				#B1 {
					display: block;
				}
				#Sheep {
					display: none;
				}
			}
			// Dræn engen
			&.area3-Category2-Action2-animated
			{
				#B2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Bridge,
				#Sheep,
				#Fence {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category2-Action2
			{
				#B2 {
					display: block;
				}
				#Bridge,
				#Sheep,
				#Fence {
					display: none;
				}
			}
			// Dyrk engen
			&.area3-Category2-Action3-animated
			{
				#B3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Bridge,
				#Sheep,
				#Fence {
					@include fadeOut(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category2-Action3
			{
				#B3 {
					display: block;
				}
				#Bridge,
				#Sheep,
				#Fence {
					display: none;
				}
			}

			// Beskyttelse
			&.area3-Category3-Action1-animated
			{
				#C1 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category3-Action1
			{
				#C1 {
					display: block;
				}
			}
			// Bedre adgang
			&.area3-Category3-Action2-animated
			{
				#C2 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
				#Gate {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category3-Action2
			{
				#C2 {
					display: block;
				}
				#Gate {
					display: block;
				}
			}
			// Vindmølleanlæg
			&.area3-Category3-Action3-animated
			{
				#C3 {
					display: block;
					@include fadeIn-Area(3s, 'ease', var(--animationDelay));
				}
			}
			&.area3-Category3-Action3
			{
				#C3 {
					display: block;
				}
			}
		}
	}
}