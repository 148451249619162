@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Timer {
	@include flex('','center','column');

	.Timer-title {
		@include titleStyling();
	}

	.Timer-box {
		@include flex('center', 'center', 'column');
		margin-top: 1em;
		
		color: $text-light;
		font-weight: bold;
		width: 10em;
		height: 3.5em;
		border-radius: 0.5em;

		&.paused {
			cursor: pointer;
			background-color: $darkBlue;
			box-shadow: 0.3em 0.3em $boxShadow;

			span {
				font-size: 1.2em;
				text-transform: uppercase;
			}
		}

		&.started {
			pointer-events: none;
			background-color: rgba($darkBlue, 0.8);

			span {
				font-size: 2.5em;
				font-weight: bold;
			}
		}
	}
}