@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.VideoPopup {
	position: absolute;
	@include flex('center');
	width: 100%;
	margin-top: 1em;

	.VideoPopup-videoCanvas 
	{
		z-index: 5;
		padding: 0.2em;
		background-color: $white;

		.VideoPopup-videoWrapper
		{
			width: 55em;
			height: 28em;
			.VideoPopup-video {
				width: 100%;
				height: 100%;
			}
		}
	}
}