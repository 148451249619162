@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.UsersPopup {
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	background: rgba($overlayBlue, 0.5);
	@include flex('center', 'center');

	.UsersPopup-content  {
		position: relative;
		width: 50em;
		color: $darkBlue;
		background-color: white;
		border-radius: 0.67em;
	
		.UsersPopup-header {
			position: relative;
			padding: 1em 0;
			background-color: $darkBlue;
			color: $text-light;
			border-top-left-radius: 0.67em;
			border-top-right-radius: 0.67em;
			.UsersPopup-title {
				font-size: 1.2em;
				text-align: center;
			}
			.UsersPopup-closeBtn {
				position: absolute;
				top: 1em;
				right: 1em;
				width: 1em;
				height: 1em;
				cursor: pointer;
				background-image: url('../../assets/images/icons/cross.svg');
			}
		}	

		.UsersPopup-body {
			padding: 1em;
			.UsersPopup-users {
				text-align: left;
				height: 20em;
				overflow: auto;
			}
		}
	}
}