@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.VoteOverview 
{
	height: 100%;
	width: 100%;
	padding: 1em;

	@include flex('space-between', '', 'column');
	@include groupBackgroundColor();

	.VoteOverview-wrapper 
	{
		@include flex('space-between', '', 'row');
		width: 100%;
		color: $text-light;
		z-index: 2;

		.VoteOverview-subAreas
		{
			width: 33%;
			
			.VoteOverview-subAreaVotes
			{
				margin-bottom: 1.25em;

				.VoteOverview-subAreaBox
				{
					@include flex('center', 'center');
					background-color: $darkBlue;
					width: 17.5em;
					padding: 0.5em;
					border-radius: 0.5em;
					margin-bottom: 0.5em;
					
					span
					{
						display: inline-block;
						font-weight: bold;
						font-size: 1.15em;
					}
					span::first-letter
					{
						text-transform: capitalize
					}
				}
				.VoteOverview-groups
				{
					@include flex('', '', 'row');
					.VoteOverview-group
					{
						opacity: 0.3;
						width: 2.5em;
						height: 2.5em;
						margin-right: 0.5em;
						border-radius: 0.25em;
						padding: 0.15em;

						border-style: solid;
						border-width: 0.15em;

						&.active {
							opacity: 1;
						}
						&.smallGroupLogo1 {
							background-image: url(../../../../assets/images/icons/group-1-logo.svg);
							background-size: contain;
							background-origin: content-box;
						}
						&.smallGroupLogo2 {
							background-image: url(../../../../assets/images/icons/group-2-logo.svg);
							background-size: contain;
							background-origin: content-box;
						}
						&.smallGroupLogo3 {
							background-image: url(../../../../assets/images/icons/group-3-logo.svg);
							background-size: contain;
							background-origin: content-box;
						}
						&.smallGroupLogo4 {
							background-image: url(../../../../assets/images/icons/group-4-logo.svg);
							background-size: contain;
							background-origin: content-box;
						}
						&.smallGroupLogo5 {
							background-image: url(../../../../assets/images/icons/group-5-logo.svg);
							background-size: contain;
							background-origin: content-box;
						}
						&.smallGroupLogo6 {
							background-image: url(../../../../assets/images/icons/group-6-logo.svg);
							background-size: contain;
							background-origin: content-box;
						}
					}
				}
			}
		}

		.VoteOverview-title
		{
			@include titleStyling();
		}

		.VoteOverview-spacer
		{
			width: 33%;
		}
	}

	.VoteOverview-bodyWrapper 
	{
		@include flex('', '', 'column');
		padding: 1em;
		.VoteOverview-subAreas 
		{
			@include flex('space-evenly','','row');
			
			.VoteOverview-infoCard
			{
				margin-right: 1em;
			}
		}
	}

	.VoteOverview-navigation
	{
		@include flex('space-between', 'flex-end', 'row');
		z-index: 2;
		width: 100%;

		.VoteOverview-gameCode
		{
			@include flex('center');
			width: 33%;
		}
		.VoteOverview-navigationButtons
		{
			@include flex('', 'flex-start', 'column');
			width: 33%;
		}
		.VoteOverview-continueButton
		{
			@include flex('flex-end', 'flex-end', 'row');
			width: 33%;
			height: 100%;
			.VoteOverview-arrow
			{
				position: relative;
				height: 3.2em;
				width: 2em;

				background-image: url(../../../../assets/images/icons/continue-arrow.svg);
				background-size: contain;
				margin-left: 0.5em;
				margin-bottom: -0.55em;
			}
		}
	}
}