@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DeleteGamePopup {
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	background: rgba($overlayBlue, 0.5);
	@include flex('center', 'center');

	.DeleteGamePopup-content  {
		position: relative;
		width: 25em;
		color: $text-light;
		background-color: $darkBlue;
		border-radius: 0.67em;
	
		.DeleteGamePopup-header {
			position: relative;
			padding: 3.25em 0 1em 0;
			background-size: auto 2em;
			background-position: center top 0.75em; 
			background-repeat: no-repeat;
			.DeleteGamePopup-title {
				font-size: 1.2em;
				text-align: center;
			}
		}	

		.DeleteGamePopup-body {
			padding: 0 1em 1em 1em;
			text-align: center;

			.DeleteGamePopup-text {
				padding: 1em;
				font-weight: 400;
				margin-bottom: 1em;
			}
			.DeleteGamePopup-buttons {
				@include flex('space-between');
				.Button {
					width: calc(50% - 0.25em);
				}
			}
		}
	}
}


@media only screen and (max-aspect-ratio: 1/1) {
	.DeleteGamePopup {
		width: 100%;
	}
}