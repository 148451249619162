@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Group 
{
	@include flex('space-between', '', 'row');
	height: 100%;
	padding: 2em;
	padding-left: 5em;
	padding-right: 5em;
	
	.castShadow {
		box-shadow: 0.5em 0.5em $boxShadow;
	}

	@include groupBackgroundColor();
	@include groupTextColor();

	.Group-background {
		position: absolute;
		overflow: hidden;
		z-index: 1;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		.Group-backgroundSvg {
			height: auto;
			width: 140%;
			@include translate(-25em, 23em);
		}
	}

	.Group-leftColumn
	{
		@include flex('space-between', '', 'column');
		margin-bottom: 3em;
		z-index: 2;

		.Group-infoPanel
		{
			@include flex('', '', 'column');
			color: $text-dark;
			line-height: 1.4em;
			background-color: $white;

			width: 20em;
			height: fit-content;
			
			padding: 0.5em;
			margin-right: 5em;
			margin-bottom: 1em;
			border-radius: 0.5em;

			.Group-panelGroupIcon
			{
				border-top-left-radius: 0.5em;
				border-top-right-radius: 0.5em;
				height: 8em;
				padding: 0.5em;

				.Group-groupLogo
				{
					width: 100%;
					height: 75%;
					background-size: contain;
	
					&.groupLogo1 {
						background-image: url(../../../../assets/images/icons/group-1-logo.svg);
					}
					&.groupLogo2 {
						background-image: url(../../../../assets/images/icons/group-2-logo.svg);
					}
					&.groupLogo3 {
						background-image: url(../../../../assets/images/icons/group-3-logo.svg);
					}
					&.groupLogo4 {
						background-image: url(../../../../assets/images/icons/group-4-logo.svg);
					}
					&.groupLogo5 {
						background-image: url(../../../../assets/images/icons/group-5-logo.svg);
					}
					&.groupLogo6 {
						background-image: url(../../../../assets/images/icons/group-6-logo.svg);
					}

				}

				.Group-panelTitle
				{
					@include flex('', 'flex-end');
					position: relative;
					text-align: center;
					width: 100%;
					height: 25%;
					span {
						font-size: 1.5em;
						font-weight: bold;
						color: $text-light;
					}
				}
			}

			.Group-panelDescription
			{
				margin: 0.5em;
				margin-top: 1em;
			}
		}
	}

	.Group-rightColumn
	{
		z-index: 2;
		@include flex('', '', 'column');

		.Group-goalPanelWrapper
		{
			@include flex('space-between', '', 'row');
			height: fit-content;

			.Group-goalPanel
			{	
				width: 16em;
				background-color: $white;
				border-radius: 0.5em;
				line-height: 1.4em;
				margin-left: 1em;

				.Group-goalPanelHeader
				{
					@include flex('space-between', '', 'row');
					
					border-top-left-radius: 0.5em;
					border-top-right-radius: 0.5em;
					padding: 0.5em;

					.Group-goalPanelHeaderText
					{
						span {
							color: $white;
							font-weight: bold;
						}
					}
				}
				.Group-goalPanelContent
				{
					padding: 0.5em;

					.Group-goalName
					{
						font-weight: bold;
						margin-bottom: 0.25em
					}

					.Group-goalDescription
					{

					}
				}
			}
		}
	}

	.GroupSidebar-logoutButton {
		z-index: 2;
		position: fixed;
		bottom: 1em;
		left: 1em;
	}
}