@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Login {
	height: 100%;
	width: 100%;
	background-color: $gradient1;
	background-image: linear-gradient(
		to top, 
		$gradient1,
		$gradient2 45%,
		$gradient3 55%,
		$gradient4 60%,
		$gradient5
	);
	
	@include groupBackgroundColor();
	@include groupTextColor();

	.space-between {
		@include flex('space-between', 'center', 'column');
	}
	
	.castShadow {
		box-shadow: 0.5em 0.5em $boxShadow;
	}

	.Login-wrap {
		@include flex('center', 'center', 'column');
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2;

		.Login-box {
			@include flex('', '', 'row');
			position: relative;
			height: 100%;
			
			.Login-form {
				@include flex('', 'center', 'column');
				position: relative;
				
				margin-right: 2em;
				margin-left: 2em;
				height: 22em;
				padding: 2em;
				padding-bottom: 0.5em;
				border-radius: 0.5em;
				box-shadow: 0.5em 0.5em $boxShadow;
				background-color: $transparentDarkBlue;

				&.feedback {
					.Login-infoLink {
						color: white;
						cursor: pointer;
						&:hover {text-decoration: underline;}
					}
				}
				input {
					display: block;
					width: 12em;
					height: 2em;
					border: none;
					border-radius: 0.56em;
					padding: 0.56em 1em;
					padding-top: 0.6em;
					margin: auto;
					margin-bottom: 1em;
					background-color: $white;
					color: $darkBlue;
					font-weight: normal;
					&::placeholder {color: $darkBlue;}
				}
				.Login-header {
					span 
					{	
						color: $text-light;
						font-weight: bold;
						text-transform: uppercase;
						font-size: 1.5em;
					}
					.Login-inputWrapper
					{
						margin-top: 1em;
					}
				}
				.Login-spacing {
					height: 3em;
				}

				.Login-userButton {
					cursor: pointer;
					color: $text-light;
					margin-bottom: 1em;
				}
				.Login-userButton:hover {
					text-decoration: underline;
				}

				.Login-gameCodeMessage {
					line-height: 1.2em;
					width: 12em;
					color: $text-light;
					text-align: center;
				}

				.Login-info {
					color: $text-light;
					width: 12em;
				}
			}

			.LoginFacilitator,
			.LoginGroup
			{
				@include align-self(center);
			}

			.LoginChooseGroup
			{
				@include flex('', 'center', 'column');
				padding: 1em;
				.LoginChooseGroup-title {
					height: 20%;
					@include titleStyling();
				}

				.LoginChooseGroup-groupsWrapper
				{
					height: 100%;
					@include flex('center', '', 'column');

					.Login-groups
					{
						@include flex('', '', 'row');
						margin-bottom: 2em;

						.Login-groupSelect
						{
							@include flex('', 'center', 'column');

							.Login-groupSelectBtn
							{
								margin-top: 1em;
							}
							
							.Login-groupBox
							{
								width: 18em;
								height: 13em;
								margin-left: 1em;
								margin-right: 1em;
							}
						}
					}
				}
			}
		}
	}
}

.Login-buttons {
	@include flex('space-between', 'center');
	.Button.Button--login { 
		margin: 0 0.5em;
		&:nth-child(1) {margin-left: 0;}
		&:nth-last-child(1) {margin-right: 0;}
	}
}

.Login-errorMessage {
	pointer-events: none;
	height: 1.5em;
	margin: 0.6em;
	margin-bottom: 1.5em;
	width: 12em;
	font-size: 0.85em;
	color: red;
	text-align: center;
}