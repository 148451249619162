@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.AreaIntro
{
	width: 100%;
	height: 100%;
	padding: 1em;

	@include groupBackgroundColor();

	.AreaIntro-bodyWrapper 
	{
		@include flex('','','column');
		width: 100%;
		height: 100%;

		.AreaIntro-header
		{
			@include flex('space-between', '', 'row');
			width: 100%;

			.AreaIntro-areaInfoWrapper
			{
				@include flex('', '', 'column');
				width: 20%;
				z-index: 3;

				.AreaIntro-areaInfo
				{
					background-color: rgba($darkBlue, 0.8);
					border-radius: 0.5em;
					padding-bottom: 1em;
					box-shadow: 0.3em 0.3em $boxShadow;

					.AreaIntro-areaInfoName
					{
						border-bottom: solid;
						border-width: 0.2em;
						padding: 0.5em;
						color: $darkBlue;
						
						span
						{
							color: $text-light;
							font-weight: bold;
							font-size: 1.5em;
						}
					}
					.AreaIntro-areaInfoDescription
					{
						padding: 0.5em;
						p, span
						{
							color: $text-light;
							line-height: 1.4em;
						}
						p {
							margin-bottom: -1em;
						}
					}
				}

				.AreaIntro-tabs
				{
					margin-top: 1em;
					z-index: 2;
					.AreaIntro-tabWrapper
					{
						@include flex('','','row');
						margin-bottom: 1em;

						.AreaIntro-tab
						{
							cursor: pointer;
							background-color: $darkBlue;
							width: 12em;
							padding: 0.5em;
							padding-left: 1em;
							border-radius: 0.5em;
							box-shadow: 0.3em 0.3em $boxShadow;
							color: $text-light;

							span
							{
								font-size: 1.2em;
								font-weight: bold;
							}

							&.active {
								color: $darkBlue;
								background-color: $offWhite;
							}
						}

						.AreaIntro-arrow
						{
							@include flex('center');
							display: none;
							margin-left: 0.5em;
							margin-top: 0.25em;
							margin-bottom: -0.25em;
							background-image: url(../../../../assets/images/icons/arrow-right.svg);
							background-size: contain;
							width: 1.5em;
							height: 2em;
					
							&.active
							{
								display: block;
							}
						}

						.AreaIntro-tabDescription
						{
							position: absolute;
							display: none;

							width: 18em;
							background-color: $transparentDarkBlue;
							border-radius: 0.5em;
							left: 15.5em;
							padding: 1em;
							padding-top: 0.5em;
							padding-right: 1em;

							.AreaIntro-tabDescriptionText {
								color: $text-light;
								line-height: 1.3em;
							}

							&.active {
								display: block;
							}

							.AreaIntro-closeButton
							{
								cursor: pointer;
								position: relative;
								background-image: url(../../../../assets/images/icons/cross.svg);
								background-size: contain;
								background-position: top 0em right 0.2em;
								height: 0.75em;
								margin-right: -0.5em;
							}
						}
					}
				}
			}

			.AreaIntro-title
			{
				@include titleStyling();
			}
			.AreaIntro-rulesButton
			{
				@include flex('flex-end', 'flex-start');
				z-index: 2;
				width: 20%;
				right: 0;
			}
		}

		.AreaIntro-navigation
		{
			@include flex('space-between', 'flex-end', 'row');
			z-index: 2;
			height: 100%;
	
			.AreaIntro-navigationButtons
			{
				@include flex('', 'flex-start', 'column');
				width: 33%;
			}
		}
	}
}