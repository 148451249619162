@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupGoals
{
	position: relative;
	height: 100%;
	@include groupBackgroundColor();
	@include groupTextColor();

	.GroupGoals-groupGoals {
		position: relative;
		width: 100%;

		.GroupGoals-groupGoalwrapper {
			@include flex('', 'center', 'row');
			width: 100%;
			margin-bottom: 0.5em;
			padding-right: 1em;

			.GroupGoals-groupGoalIcon {
				width: 2.5em;
				height: 2.5em;
				@include flex('', '', 'column');

				&.group1 {
					#Rectangle_12-2 {
						fill: $group1;
					}
				}
				&.group2 {
					#Rectangle_12-2 {
						fill: $group2;
					}
				}
				&.group3 {
					#Rectangle_12-2 {
						fill: $group3;
					}
				}
				&.group4 {
					#Rectangle_12-2 {
						fill: $group4;
					}
				}
				&.group5 {
					#Rectangle_12-2 {
						fill: $group5;
					}
				}
				&.group6 {
					#Rectangle_12-2 {
						fill: $group6;
					}
				}

				.GroupGoals-eyeIcon {
					width: 100%;
				}
			}

			.GroupGoals-groupGoalIcon:hover {
				cursor: pointer;
			}

			.GroupGoals-groupGoal {
				.GroupGoals-groupGoalTitle {
					font-weight: bold;
				}
			}
		}
	}

	.GroupGoals-goalInformation {
		background-color: $white;
		position: absolute;
		z-index: 5;

		margin-bottom: 0.75em;
		margin-right: 0.75em;

		max-height: fit-content;

		top: 0em;
		bottom: 0em;
		left: 2.15em;

		border: solid;
		border-radius: 0.5em;

		.GroupGoals-closeIcon {
			position: absolute;
			cursor: pointer;
			margin: 0.15em;
			width: 0.8em;
			height: 0.8em;
			right: 0em;
			
			&.group1 {
				#Rectangle_128,
				#Rectangle_127 {
					fill: $group1;
				}
			}
			&.group2 {
				#Rectangle_128,
				#Rectangle_127 {
					fill: $group2;
				}
			}
			&.group3 {
				#Rectangle_128,
				#Rectangle_127 {
					fill: $group3;
				}
			}
			&.group4 {
				#Rectangle_128,
				#Rectangle_127 {
					fill: $group4;
				}
			}
			&.group5 {
				#Rectangle_128,
				#Rectangle_127 {
					fill: $group5;
				}
			}
			&.group6 {
				#Rectangle_128,
				#Rectangle_127 {
					fill: $group6;
				}
			}
		}

		.GroupGoals-goalName {
			padding-left: 0.5em;
			padding-top: 0.25em;
			span {
				font-weight: bold;
				font-size: 0.8em;
			}
		}

		.GroupGoals-goalInfoDescription {
			padding: 0.5em;
			padding-right: 1em;
			.GroupGoals-goalInfoText {
				font-size: 0.8em;
			}
		}
	}
}