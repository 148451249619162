/* BODY TEXT */
@font-face {
	font-family: 'metropolis';
	src: url('../assets/fonts/Metropolis-Regular.ttf');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'metropolis';
	src: url('../assets/fonts/Metropolis-Medium.ttf');
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: 'metropolis';
	src: url('../assets/fonts/Metropolis-SemiBold.ttf');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'metropolis';
	src: url('../assets/fonts/Metropolis-ExtraBold.ttf');
	font-style: normal;
	font-weight: 500;
}

// font-family: Frutiger, sans-serif;
