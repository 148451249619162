@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.GameIntro
{
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;

	// Makes object invisible, but still takes space in layout
	.inactive {
		opacity: 0;
	}

	.castShadow {
		box-shadow: 0.5em 0.5em $boxShadow;
	}

	.GameIntro-pageWrapper
	{
		@include flex('space-between', '', 'column');
		padding: 1em;
		height: 100%;
		width: 100%;

		background-image: url(../../../../assets/images/full-area.svg);
		background-size: 110% auto;
		background-repeat: no-repeat;
		@include transition(1s, 'background-position');

		&.groupsShown
		{
			background-position: top calc(50% + 2.5em) left calc(50% + 3em);
		}

		&.initialPosition
		{
			background-position: bottom -4em left calc(50% + 3em);
		}

		.GameIntro-contentWrapper
		{
			@include flex('', '', 'row');
			.GameIntro-infoTabs
			{
				margin-top: 1.5em;
				width: 33%;
				.GameIntro-infoTabWrapper {
					@include flex('', '', 'row');
					margin-bottom: 1em;
					margin-left: 1em;

					.GameIntro-infoTab
					{
						cursor: pointer;
						@include flex('', 'center');
						width: 12em;
						padding: 0.5em;
						padding-left: 1em;
						border-radius: 0.5em;

						background-color: $darkBlue;
						color: $text-light;

						span {
							font-weight: bold;
						}

						&.active 
						{
							background-color: $offWhite;
							color: $darkBlue;
						}
					}
					.GameIntro-infoTabArrow
					{
						@include flex('center');
						display: none;
						margin-left: 0.5em;
						margin-top: 0.25em;
						margin-bottom: -0.25em;
						background-image: url(../../../../assets/images/icons/arrow-right.svg);
						background-size: contain;
						width: 1.5em;
						height: 2em;
				
						&.active
						{
							display: block;
						}
					}

					.GameIntro-expandable
					{
						position: absolute;
						display: none;
						left: 16em;
						top: 2.5em;

						border-radius: 0.5em;
						padding: 1em;

						background-color: $transparentDarkBlue;
						color: $text-light;

						width: 29em;
						line-height: 1.45em;

						&.active
						{
							display: block;
						}
					}
				}
			}
			.GameIntro-titleWrapper
			{
				@include flex('', 'center', 'column');
				width: 34%;
				.GameIntro-logo
				{
					height: 15em;
					width: 40em;
					background-image: url(../../../../assets/images/frontpage-logo.svg);
					background-size: contain;
				}
			}

			.GameIntro-rulesButton
			{
				width: 33%;	
			}
		}

		.Lobby-groupsWrapper
		{
			width: 100%;
			.Lobby-groups
			{
				@include flex('center', '', 'row');
				margin-top: 15em;
				width: 100%;

				.Lobby-group 
				{
					@include transition(0.25s, 'opacity');

					width: 13em;
					height: 11em;
					margin-left: 1em;
					margin-right: 1em;
				}
			}
		}

		.GameIntro-navigation
		{
			@include flex('space-between', 'flex-end', 'row');

			.GameIntro-gameCodeWrapper {
				position: relative;
				@include flex('center', 'center');
				width: 48.4em;
				height: 7.8em;
				background-color: rgba($white, 0.6);
				border-radius: 0.66em;

				.GameIntro-gameCodeIcon {
					position: absolute;
					width: 2.8em;
					height: 2.8em;
					top: 0.9em;
					left: 1.5em;
					background-image: url(../../../../assets/images/icons/info-icon.svg);
					background-size: contain;
				}

				.GameIntro-gameCode {
					color: $darkBlue;
					text-align: center;
					font-size: 1.66em;
					line-height: 1.25em;
				}
			}
				
			.GameIntro-presentationWrapper {
				position: relative;
				@include flex('center', 'center');
				width: 48.4em;
				height: 4.8em;
				background-color: rgba($white, 0.6);
				border-radius: 0.66em;
				margin-bottom: 2em;

				.GameIntro-presentationIcon {
					position: absolute;
					width: 2.8em;
					height: 2.8em;
					top: 0.9em;
					left: 1.5em;
					background-image: url(../../../../assets/images/icons/info-icon.svg);
					background-size: contain;
				}

				.GameIntro-presentationText
				{
					text-align: center;
					font-size: 1.5em;
				}
			}

			.GameIntro-navigationButtons
			{
				@include flex('', 'flex-start', 'column');
				width: 33%;
				margin-bottom: 1em;
			}
		}
	}
}