@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.NextPageButton
{
	width: 33%;
	display: none;
	margin-bottom: 1em;

	&.active {
		@include flex('flex-end', 'center', 'row');
	}

	.NextPageButton-arrow
	{
		position: relative;
		height: 3.2em;
		width: 2em;

		background-image: url(../../../assets/images/icons/continue-arrow.svg);
		background-size: contain;
		margin-left: 0.5em;
		margin-bottom: -0.55em;
	}
}