@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';

.GroupInfo
{
	@include groupBackgroundColor();
	@include groupTextColor();
	
	.GroupInfo-currentGroup {
		margin-bottom: 0.5em;
		background-color: $white;
		border-radius: 0.5em;
		width: 100%;

		.GroupInfo-currentGroupInfo {
			padding: 0.5em;
			
			.GroupInfo-currentGroupLogo {
				@include flex('', 'flex-end', 'column');
				border-top-left-radius: 0.5em;
				border-top-right-radius: 0.5em;
				height: 7.5em;
				margin-bottom: 1em;

				padding: 0.5em;
				padding-bottom: 1.5em;
				background-origin: content-box;
				background-size: contain;

				&.groupLogo1 {
					background-image: url(../../../assets/images/icons/group-1-logo.svg);
				}
				&.groupLogo2 {
					background-image: url(../../../assets/images/icons/group-2-logo.svg);
				}
				&.groupLogo3 {
					background-image: url(../../../assets/images/icons/group-3-logo.svg);
				}
				&.groupLogo4 {
					background-image: url(../../../assets/images/icons/group-4-logo.svg);
				}
				&.groupLogo5 {
					background-image: url(../../../assets/images/icons/group-5-logo.svg);
				}
				&.groupLogo6 {
					background-image: url(../../../assets/images/icons/group-6-logo.svg);
				}

				.GroupGoals-closeIcon {
					cursor: pointer;
					background-image: url('../../../assets/images/icons/cross.svg');
					background-size: contain;
					width: 1em;
					height: 1em;
				}

				.GroupInfo-groupName {
					@include flex('center', 'flex-end');
					width: 100%;
					height: 100%;
					margin-top: 1.25em;
					margin-bottom: -1.25em;

					span {
						font-weight: bold;
						color: $text-light;
					}
				}
			}

			.GroupInfo-group {
				margin-left: 1em;
			}
		}

		.GroupInfo-groupPoints 
		{
			width: 100%;
			@include flex('', '', 'column');

			.GroupInfo-groupPointsHeader 
			{
				position: relative;
				@include flex('space-between', 'center', 'row');
				padding: 0.4em;
				padding-left: 1em;
				padding-right: 1em;

				.GroupInfo-groupPointsTitle 
				{
					span {
						color: $text-light;
						font-weight: bold;
					}
				}
				.GroupInfo-groupPointsTotal 
				{
					position: absolute;
					right: 1em;
					bottom: -0.25em;

					border-radius: 0.4em;
					border-style: solid;
					border-width: 0.25em;

					padding: 0.5em;
					padding-right: 0.75em;
					padding-left: 0.75em;
					background-color: $white;

					span {
						font-size: 1.2em;
						font-weight: 500;
					}
				}
			}
	
			.GroupInfo-groupPointsBody 
			{
				padding: 1em;
				padding-top: 0.75em;
				.GroupInfo-groupPointsDescription 
				{
					margin-bottom: 0.75em;
					width: 75%;
					span {
						font-size: 0.9em;
					}
				}

				.GroupInfo-groupPointsTrophyWrapper 
				{
					@include flex('space-between', '', 'row');
					.GroupInfo-groupPointsTrophyContainer 
					{
						@include flex('', 'center', 'row');
						border-radius: 0.4em;
						padding-right: 0.4em;
						padding-left: 0.4em;
						
						.GroupInfo-groupPointsTrophy
						{
							position: relative;

							&.small 
							{
								background-image: url(../../../assets/images/icons/trophy-small.svg);
								background-size: contain;
								width: 2em;
								height: 3em;
							}

							&.large 
							{
								background-image: url(../../../assets/images/icons/trophy-large.svg);
								background-size: contain;
								width: 3em;
								height: 4em;
								margin-top: -0.75em;
								margin-right: -0.5em;
							}
						}
						.GroupInfo-groupPointsThreshold 
						{
							span
							{
								color: $text-light;
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
}