@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Attention 
{
	@include flex('', '', 'column');
	position: relative;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	padding: 1em;

	.Attention-textWrapper {
		@include flex('center', 'center');
		position: relative;
		z-index: 2;
		width: 100%;
		height: 100%;

		.Attention-text
		{
			@include flex('center', 'center', 'column');
			background-color: $transparentDarkBlue;
			padding: 3em;
			padding-left: 7em;
			padding-right: 7em;

			border-radius: 1em;
			box-shadow: 0.3em 0.3em $boxShadow;

			.Attention-eyeIcon
			{
				background-image: url(../../../../assets/images/icons/eye.svg);
				background-size: contain;
				width: 10em;
				height: 5em;
				margin-bottom: 0.5em;
			}

			span
			{
				font-size: 2em;
				font-weight: 500;
				color: $text-light;
			}
		}
	}
	.Attention-logoutButton
	{
		@include flex('', 'flex-end');
		position: relative;
		z-index: 2;
	}
}