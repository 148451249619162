@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.AreaInfo {
	position: relative;
	@include flex('', '', 'row');
	height: 100%;
	width: 100%;
	
	padding-top: 1em;
	padding-left: 1em;
	padding-right: 1em;

	@include groupBackgroundColor();

	.AreaInfo-groupInfoBar {
		@include flex('space-between','','column');

		.AreaInfo-logoutButton {
			width: 7em;
			z-index: 2;
			cursor: pointer;
			position: relative;
			margin: 1em;
		}
	}

	.castShadow {
		box-shadow: 0.5em 0.5em $boxShadow;
	}

	.AreaInfo-closeIcon {
		cursor: pointer;
		background-image: url('../../../../assets/images/icons/cross.svg');
		background-size: 80% auto;
		width: 2em;
		height: 2em;
	}

	.AreaInfo-expandIconDown {
		cursor: pointer;
		background-image: url('../../../../assets/images/icons/arrow-down.svg');
		background-position: top calc(50% + 0.3em) right calc(50% - 0.2em);
		background-size: cover;
		width: 2em;
		height: 2em;
	}

	.AreaInfo-content
	{
		@include flex('', '', 'row');

		.AreaInfo-header
		{
			position: relative;
			z-index: 2;
			
			.AreaInfo-subAreaWrapper
			{
				@include flex('', '', 'row');

				.AreaInfo-subAreaButton
				{
					@include flex('center','center','');
					cursor: pointer;
					background-color: $darkBlue;
					border-radius: 0.8em;
					width: 13em;
					height: 2.5em;
					margin-bottom: 1em;
					margin-right: 3em;
					color: $text-light;
		
					.AreaInfo-subAreaButtonText
					{
						@include noHighlight();
						font-size: 1.2em;
						font-weight: bold;
					}
					.AreaInfo-subAreaButtonText:first-letter
					{
						text-transform: capitalize;
					}
				}
				
				.Active
				{
					background-Color: $offWhite;
					color: $darkBlue;
				}

				.AreaInfo-rulesButton {
					width: 5em;
					margin-left: 3em;
					@include flex('flex-end', 'flex-start');
					z-index: 2;
				}
			}
		}

		.AreaInfo-body
		{
			position: relative;
			z-index: 2;
			margin-right: 2em;
			.AreaInfo-subAreaInfoWrapper
			{
				@include flex('', '', 'column');
				border-radius: 1em;

				.AreaInfo-description
				{
					background-color: $transparentDarkBlue;
					color: $white;
					
					padding: 1em;
					margin-bottom: 1em;
					border-radius: 1em;
					line-height: 1.3em;
				}
			}
		}
	}
}