@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.AreaScore
{
	@include flex('space-between', 'center', 'column');

	height: 100%;
	width: 100%;
	padding: 1em;

	.AreaScore-title
	{
		@include titleStyling();
	}

	.AreaScore-scoreboard
	{
		background-color: $transparentDarkBlue;
		z-index: 2;
		width: 35em;
		border-radius: 0.5em;
		padding: 1em;

		.AreaScore-scoreboardPoints
		{
			@include flex('space-between', '', 'column');
			width: 100%;
			background-color: white;
			box-shadow: 0.3em 0.3em $boxShadow;
			border-radius: 0.5em;
			padding: 0.75em;
			padding-top: 0.5em;
			padding-bottom: 0.5em;

			.AreaScore-scoreboardWrapper
			{
				margin-top: 0.5em;
				span {
					color: $darkBlue;
					font-weight: bold;
					text-transform: uppercase;
				}

				.AreaScore-scoreboardBar
				{
					@include flex('flex-start', 'center', 'row');
					width: 100%;
					height: 1.25em;
					
					margin-top: 0.15em;
					margin-bottom: 0.2em;
					border-radius: 0.25em;

					padding-left: 0.1em;
					padding-right: 0.1em;
					padding-bottom: 0.1em;
					padding-top: 0.1em;

					background-color: $darkBlue;

					.AreaScore-barSegmentWrapper
					{
						height: 100%;

						.AreaScore-barSegment
						{
							height: 100%;
							border-color: $darkBlue;
							border-radius: 0.2em;
							border-style: solid;
							border-width: 0.1em;

							&.animated {
								opacity: 0;
								@include pointBarGrow(0.15s);
							}

							&.area1 {
								background-image: linear-gradient(
									to top,
									$pointBarGradient1,
									$pointBarGradient2,
								);
							}
							
							&.area2 {
								background-image: linear-gradient(
									to top,
									$pointBarGradient3,
									$pointBarGradient4,
								);
							}
							
							&.area3 {
								background-image: linear-gradient(
									to top,
									$pointBarGradient5,
									$pointBarGradient6,
								);
							}

						}
					}
				}
			}
		}
	}

	.AreaScore-navigation
	{
		@include flex('space-between', 'flex-end', 'row');
		z-index: 2;
		width: 100%;

		.AreaScore-navigationButtons
		{
			@include flex('', 'flex-start', 'column');
			width: 33%;
		}

		.AreaScore-gameCode
		{
			@include flex('center');
			width: 34%;
		}
	}
}