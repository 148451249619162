@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	@include flex('center', 'center');
	position: relative;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	color: black;
	background-color: white;
	padding: 0.1em 0.5em;
	border-radius: 0.125em;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	span {
		display: inline-block;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: darken(white, 5%);
	}
	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		background-image: url('../../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: center right;
		background-repeat: no-repeat;
	}
	&.continue {
		color: $darkBlue;
		background-color: $offWhite;
		
		line-height: 1;
		border-radius: 0.5em;
		height: 2.8em;
		padding-top: 0.15em;
		width: 17em;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1.25em;
			font-weight: 500;
		}
	}
	&.rules {
		background-color: $darkBlue;
		color: $text-light;

		line-height: 1;
		border-radius: 0.5em;
		
		padding-left: 2.5em;
		padding-right: 0.8em;
		padding-top: 0.15em;
		height: 1.8em;

		background-image: url('../../../assets/images/icons/rules.svg');
		background-position: bottom calc(50%) left 0.5em;
		background-size: 17% auto;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1em;
		}
	}
	&.popup {
		background-color: $offWhite;
		color: $darkBlue;

		border-radius: 0.5em;
		
		padding-left: 0.8em;
		padding-right: 0.8em;
		padding-top: 0.4em;
		padding-bottom: 0.4em;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1em;
		}
	}
	&.lobby {
		background-color: $darkBlue;
		color: $text-light;

		border-radius: 0.5em;
		
		line-height: 1;
		height: 1.75em;
		padding-left: 2.5em;
		padding-right: 1.25em;
		padding-top: 0.15em;

		background-image: url('../../../assets/images/icons/lobby.svg');
		background-position: bottom calc(50%) left 0.5em;
		background-size: 17% auto;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1em;
		}
	}
	&.logout {
		text-transform: none;
		
		background-color: $darkBlue;
		color: $text-light;

		line-height: 1;
		border-radius: 0.5em;
		
		padding-left: 2.5em;
		padding-right: 0.8em;
		padding-top: 0.15em;
		height: 1.8em;

		background-image: url('../../../assets/images/icons/logout.svg');
		background-position: bottom calc(50%) left 1em;
		background-size: 17% auto;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1em;
		}
	}
	&.cookies,
	&.play {
		background-color: $offWhite ;
		color: $darkBlue;

		border-radius: 0.5em;
		
		padding-top: 0.3em;
		height: 3em;
		width: 100%;
		line-height: 1;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1.5em;
		}

		&.loading {
			background-image: url('../../../assets/images/icon-loading-dark.svg');
			background-size: auto 80%, 100%;
			background-position: center right, center center;
			background-repeat: no-repeat;
		}
	}
	&.delete {
		text-transform: none;
		
		background-color: $deleteButtonRed;
		color: $text-light;

		border-radius: 0.5em;
		
		height: 1.9em;
		width: 1.75em;

		background-image: url('../../../assets/images/icons/delete.svg');
		background-size: auto 1.25em;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1em;
		}
	}
	&.login {
		@include flex('center', 'center');
		
		line-height: 1;
		width: 7em;
		
		height: 2.2em;
		padding-top: 0.15em;
		border-radius: 0.5em;
		margin-bottom: 1em;

		font-weight: bold;
		color: $darkBlue;
		background-color: $offWhite;
		box-shadow: 0.25em 0.25em $boxShadow;
		
		&.blue {
			background-color: $darkBlue;
			color: $offWhite;
			text-transform: none;

			&.loading {
				background-image: url('../../../assets/images/icon-loading.svg');
				background-size: auto 80%, 100%;
				background-position: center right, center center;
				background-repeat: no-repeat;
			}
		}

		&.loading {
			background-image: url('../../../assets/images/icon-loading-dark.svg');
			background-size: auto 80%, 100%;
			background-position: center right, center center;
			background-repeat: no-repeat;
		}
	}

	&.startGame {
		background-color: $darkBlue;
		color: $text-light;

		border-radius: 0.4em;
		
		padding-left: 3em;
		padding-right: 3em;
		padding-top: 0.45em;
		padding-bottom: 0.45em;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1em;
		}
	}
}