@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.FinalArea
{
	position: relative;
	overflow: hidden;
	@include groupBackgroundColor();
	@include flex('space-between', '', 'column');

	height: 100%;
	width: 100%;
	padding: 1em;


	.FinalArea-header
	{
		@include flex('', 'center', 'column');
		width: 100%;
		
		.FinalArea-title
		{
			@include titleStyling();
		}

		.FinalArea-subTitle
		{
			margin-top: 1em;
			margin-bottom: 1em;

			.FinalArea-subTitleText
			{
				line-height: 1.4em;
				text-align: center;
				font-weight: bold;
				color: $darkBlue;
			}
		}
	}

	.FinalArea-backgroundWrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.FinalArea-navigation
	{
		@include flex('space-between', 'flex-end', 'row');
		z-index: 2;
		width: 100%;

		.FinalArea-gameCode
		{
			@include flex('center');
			width: 33%;
		}
		.FinalArea-navigationButtons
		{
			@include flex('', 'flex-start', 'column');
			width: 34%;
		}
	}
}