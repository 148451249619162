@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupSidebar {
	height: 95%;
	
	.castShadow {
		box-shadow: 0.5em 0.5em $boxShadow;
	}

	@include groupBackgroundColor();
	@include groupTextColor();
	
	.GroupSidebar-closeIcon {
		cursor: pointer;
		background-image: url('../../../assets/images/icons/cross.svg');
		background-size: 80% auto;
		width: 1.25em;
		height: 1.25em;
	}

	.GroupSidebar-expandIconDown {
		cursor: pointer;
		background-image: url('../../../assets/images/icons/arrow-down.svg');
		background-position: top calc(50% + 0.3em) right calc(50% - 0.2em);
		background-size: cover;
		width: 1.25em;
		height: 1.25em;
	}

	.GroupSidebar-groupInfoBar {
		position: relative;
		z-index: 3;
		height: 100%;
		overflow-y: scroll;

		@include hide-scrollbar();

		margin-right: 1em;
		width: 20em;
		 // Adding padding to push hidden scrollbar, to fix box shadow cutoff
		padding-right: 1em;

		.GroupSidebar-otherGroupInfo {
			position: relative;
			border-radius: 0.5em;
			padding-bottom: 0.5em;
			padding-top: 0.5em;
			margin-bottom: 0.6em;

			.GroupSidebar-otherGroupTitleWrapper {
				@include flex('space-between', 'center', row);
				margin-left: 1em;
				margin-right: 1em;

				.GroupSidebar-otherGroupTitle {
					color: $text-light;
					font-weight: bold;
					font-size: 1em;
				}
			}

			.GroupSidebar-groupInfoExpandable {
				position: absolute;
				overflow: hidden;
				background-color: $white;
				
				height: 0%;
				width: 100%;
				padding-left: 1.5em;
				
				border-bottom-left-radius: 0.5em;
				border-bottom-right-radius: 0.5em;

				@include transition(0.15s);
				z-index: 3;

				.GroupSidebar-groupInfo {
					position: relative;
					height: 100%;
					color: $text-dark;
				}

				&.expanded {
					padding-top: 0.75em;
					height: 10em;
					@include transition(0.25s, 'height');
				}
			}
		}
	}
}