@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/animations';
html {
  position: relative;
  font-size: calc(18 * ((100vw / 1920)));
  height: 100%;
}

body {
  position: relative;
	height: 100%;
  width:100%;
  margin: 0;
  padding: 0;
  font-size: 1em;
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: hidden;
}

* {
	box-sizing: border-box;
  font-family: 'metropolis';
	background-repeat: no-repeat;
  background-position: center center;
}

button {
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
  font-size: inherit;
  border-style: none;
  outline: none;
  box-shadow: none;
  @include appearance(none);
}