@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PdfLink
{
	position: relative;
	@include flex('', 'center');

	&.dark {
		
		background-color: $darkBlue;

		border-radius: 0.4em;
		
		padding-left: 0.75em;
		padding-right: 0.75em;
		padding-top: 0.45em;
		padding-bottom: 0.45em;

		margin-bottom: 0.5em;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		a {
			font-weight: bold;
			text-decoration: none;
			text-transform: uppercase;
			color: $text-light;
			font-size: 1em;
		}
	}

	&.light {
		height: 2.25em;
		border-radius: 0.5em;
		background-color: $offWhite;
		box-shadow: 0.25em 0.25em $boxShadow;
		padding: 0.75em;
		padding-top: 0.85em;
		margin-bottom: 1.3em;
		width: 100%;

		a
		{
			text-decoration: none;
			text-align: center;
			position: relative;
			width: 100%;
			color: $darkBlue;
			font-size: 1.2em;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
}