@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.InfoCard{
	background-color: $transparentDarkBlue;
	color: $text-light;
	border-radius: 1em;
	box-shadow: 0.3em 0.3em $boxShadow;

	.InfoCard-header
	{
		@include flex('', '', 'row');
		padding: 1em;

		&.expanded {
			border-bottom: solid;
			border-width: 0.15em;
			border-color: $darkBlue;
		}

		.InfoCard-title
		{
			@include flex('', '', 'row');
			font-weight: bold;
			width: 100%;
			font-size: 1.25em;
		}
		.InfoCard-headerText
		{
			@include flex('', '', 'row');
			@include noHighlight();
			pointer-events: none;
			width: 100%;
		}
		.InfoCard-headerItem
		{
			padding: 1em;
			margin-left: 1em;
		}
		.InfoCard-closeButton
		{
			cursor: pointer;
			background-image: url(../../../assets/images/icons/cross.svg);
			background-size: contain;
			height: 1em;
			width: 1em;
		}

		.InfoCard-toggleButton
		{
			cursor: pointer;
			background-size: contain;

			&.closed {
				background-image: url(../../../assets/images/icons/arrow-down.svg);
				height: 1.5em;
				width: 1.5em;
				margin-right: -0.25em;
			}
			&.expanded {
				background-image: url(../../../assets/images/icons/cross.svg);
				height: 1em;
				width: 1em;
			}
		}
	}

	.InfoCard-body
	{
		display: none;
		
		&.expanded {
			display: block;
		}

		.InfoCard-item
		{
			border-bottom: solid;
			border-color: $darkBlue;
			border-width: 0.15em;

			&.NoBorder {
				border-bottom: none;
			}

			.InfoCard-titleWrapper
			{
				@include flex('','','row');
				width: 100%;

				.InfoCard-title
				{
					@include noHighlight();
					pointer-events: none;
					padding-bottom: 0.25em;
					padding-top: 0.5em;
					padding-left: 1em;
					font-weight: bold;
				}

				.InfoCard-isExpensive {
					background-image: url(../../../assets/images/icons/expensive.svg);
					background-size: contain;
					width: 1.55em;
					height: auto;
					margin-left: 0.5em;
				}
			}

			.InfoCard-description
			{
				@include noHighlight();
				pointer-events: none;
				line-height: 1.25em;
				padding-left: 1em;
				padding-right: 1em;
				padding-bottom: 1em;
			}
		}
	}
}