/* Colors */
$titleBlue: #03324A;
$darkBlue: #00324A;
$offWhite: #F4F4F4;
$white: #ffffff;
$lightGrey: #e0e0e0;
$yellow: #FAFF7F;
$overlayBlue: #103345;

$gameCodeGreenFrom: #C6D6B5;
$gameCodeGreenTo: #89AB67;

$pointBarGradient6: #9BEBDF;
$pointBarGradient5: #1BAF98;
$pointBarGradient4: #81E282;
$pointBarGradient3: #39AA3A;
$pointBarGradient2: #DEDA8D;
$pointBarGradient1: #83A154;

$groupReadyGreen: rgba(#3C8B0C, 0.6);
$popupBlue: #00324A;

$transparentDarkBlue: rgba(#00324A, 0.8);
$boxShadow: rgba(0, 0, 0, 0.15);

$gradient1: #ABCDEB;
$gradient2: #F4F8FB;
$gradient3: #E6F0F6;
$gradient4: #D2E5EF;
$gradient5: #2F6F8E;

$deleteButtonRed: #80302D;

$text-light: #ffffff;
$text-dark: #000000;

// Group Colors //
$group1: #397F83;
$group2: #1B3F1C;
$group3: #9D5B41;
$group4: #F1934B;
$group5: #212A53;
$group6: #7D64A8;