@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CloudEngine
{
	.CloudEngine-cloud
	{
		pointer-events: none;
		z-index: 1;
		position: absolute;
		width: 10em;
		height: 3em;
		left: -20em;

		background-image: url(../../assets/images/cloud.svg);
		background-size: contain;

		@include floatRight();
	}
}