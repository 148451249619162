@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	overflow-x: hidden;
	overflow-y: hidden;
	
	@include flex('space-between', '', 'column');
	height: 100%;
	width: 100%;
	position: relative;
	background-color: $gradient1;
	background-image: linear-gradient(
		to top, 
		$gradient1,
		$gradient2 45%,
		$gradient3 55%,
		$gradient4 60%,
		$gradient5
	);
	text-align: center;
	padding: 1em;

	.Settings-background {
		position: absolute;
		overflow: hidden;
		z-index: 1;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		.Settings-backgroundSvg {
			height: auto;
			width: 140%;
			@include translate(-25em, 23em);
		}
	}

	.cloudEngine {
		position: absolute;
		height: 100%;
		width: 100%;
	}

	.Settings-titleWrapper
	{
		@include flex('center', '', 'row');
		width: 100%;
		.Settings-title
		{
			@include titleStyling();
		}
	}

	.Settings-dialogueWrapper
	{
		@include flex('center', '', 'row');
		z-index: 2;
		width: 100%;
		margin-top: 2em;
		.Settings-gameWrapper
		{
			@include flex('', 'center', 'column');

			.Settings-game
			{
				@include flex('', 'center', 'column');
				width: 43.3em;
				// height: 23.2em;
				padding: 0.5em;
				padding-bottom: 3.2em;
				background-color: $transparentDarkBlue;
				border-radius: 0.5em;
				box-shadow: 0.5em 0.5em $boxShadow;

				.Settings-gameTitle
				{
					margin-top: 3.2em;
					margin-bottom: 2.4em;
					span {
						font-size: 2em;
						font-weight: bold;
						color: $text-light;
						text-transform: uppercase;
					}
				}

				.Settings-startGameButton {
					margin: 1.3em;
				}

				.Settings-newGameButton {
					&.hasMargin {
						margin-bottom: 1.3em;
					}
				}
				.Settings-usersBtn {
					margin: 1.3em;
				}

				.Settings-gamePdfLinks,
				.Settings-newGameButton,
				.Settings-startGameButton,
				.Settings-materialsButton,
				.Settings-usersBtn {
					width: 20.5em;
				}
			}
		}
	}

	.Settings-navigation {
		@include flex('space-between', 'center');
		z-index: 2;
	}



	.Settings-funds {
		position: absolute;
		@include flex('space-between', 'flex-start', 'column');
		bottom: 1em;
		right: 1em;
		z-index: 2;
		background-color: rgba(white, 0.15);
		padding: 0.5em;
		border-radius: 0.5em;
		span {
			text-align: left;
			font-size: 1.1em;
			color: $text-light;
			padding-bottom: 0.1em;
			margin-bottom: 0.25em;
			border-bottom-style: solid;
			border-bottom-width: 0.1em;
			border-bottom-color: rgba(white, 0.2);
			width: 100%;
		}
		.Settings-nordeaFund {
			background-image: url(../../assets/images/logos/fondlogoer/NordeaFonden.png);
			background-size: contain;
			width: 10em;
			height: 4em;
		}
		.Settings-openairFund {
			background-image: url(../../assets/images/logos/friluftsrådet.svg);
			background-size: contain;
			width: 10em;
			height: 5em;
			margin-top: 0.25em;
		}
		.Settings-juneFund {
			background-image: url(../../assets/images/logos/fondlogoer/15-juni-fonden.png);
			background-size: contain;
			width: 12em;
			height: 2em;
			margin-top: 0.25em;
		}
	}

}