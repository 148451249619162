@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Vote 
{
	@include flex('', '', 'row');
	width: 100%;
	height: 100%;
	
	padding-top: 1em;
	padding-left: 1em;
	padding-right: 1em;
	
	@include groupBackgroundColor();

	.castShadow {
		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);
	}

	.Vote-groupInfoBar
	{
		@include flex('space-between','','column');

		.Vote-logoutButton {
			width: 7em;
			z-index: 2;
			cursor: pointer;
			position: relative;
			margin: 1em;
		}
	}

	.Vote-contentWrapper {
		@include flex('', '', 'column');
		z-index: 2;

		.Vote-bodyWrapper 
		{
			@include flex('space-between', '', 'column');
			height: 100%;
			width: 100%;
			padding-bottom: 1em;

			.Vote-subAreas 
			{
				@include flex('','','row');
				
				.Vote-subject
				{
					background-color: $transparentDarkBlue;
					border-radius: 1em;
					width: 30%;
					color: $text-light;
					margin-right: 1em;

					.Vote-subjectTitle
					{
						padding: 1em;
						font-weight: bold;
						border-bottom: solid;
						border-color: $darkBlue;
						border-width: 0.25em;
					}
					.Vote-subjectTitle:first-letter
					{
						text-transform: capitalize;
					}
					
					.Vote-action 
					{
						@include flex('', '', 'row');
						padding: 0.25em;
						padding-left: 1em;
						padding-right: 1em;

						min-height: 12em;
						
						&.hasBorder {
							border-bottom: solid;
							border-color: $darkBlue;
							border-width: 0.25em;
						}

						.Vote-actionWrapper {
							@include flex('', '', 'column');
							padding-right: 0.5em;

							.Vote-actionIdWrapper
							{
								@include flex('', '', 'row');
								.Vote-actionId 
								{
									font-weight: bold;
									padding-top: 0.5em;
									padding-bottom: 0.25em;
									
									span {
										font-size: 1.2em;
									}
								}

								.Vote-isExpensive {
									background-image: url(../../../../assets/images/icons/expensive.svg);
									background-size: contain;
									width: 2.5em;
									height: auto;
									margin-left: 0.5em;
								}
							}

							.Vote-actionDescription 
							{
								padding-bottom: 0.5em;
								padding-right: 1em;
								
								span {
									line-height: 1.3em;
								}
							}
						}

						.Vote-actionBox {
							cursor: pointer;
							@include flex('center', 'center', '');

							min-width: 2.75em;
							max-width: 2.75em;
							min-height: 2.75em;
							max-height: 2.75em;

							border-radius: 0.5em;
							border-style: dashed;
							border-color: #000;
							border-width: 0.1em;

							.Vote-actionBoxText {
								font-size: 2em;
								font-weight: bold;
								pointer-events: none;
							}

							&.noVote
							{
								@include box-shadow-inner(0.2em, 0.2em, 0.1em, 0, $boxShadow);
								background-color: $lightGrey;
							}

							&.whiteBorder
							{
								border: solid;
								color: $white;
								border-width: 0.15em;
							}
						}
					}
				}
			}

			.Vote-votes
			{
				@include flex('', 'flex-end', 'row');

				.Vote-voteDialogue 
				{
					@include flex('space-evenly', '', 'column');
					width: 14em;

					.Vote-votesTitle
					{
						color: $text-light;
						font-weight: bold;
						padding: 0.5em;
						padding-top: 0.25em;
						padding-bottom: 0.25em;

						border-top-left-radius: 0.5em;
						border-top-right-radius: 0.5em;
					}

					.Vote-votesWrapper {
						@include flex('space-evenly', 'center', 'row');
						background-color: rgba($white, 0.6);
						height: 5em;

						border-bottom-left-radius: 0.5em;
						border-bottom-right-radius: 0.5em;

						.Vote-vote
						{
							cursor: pointer;
							@include flex('center', 'center', '');

							border-radius: 0.25em;
							width: 2.5em;
							height: 3em;

							.Vote-voteText
							{
								font-size: 2em;
								font-weight: bold;
								color: $white;
							}

							&.isUsed {
								opacity: 0.35;
								pointer-events: none;
							}
						}

						.Vote-voteIndicatorIcon {
							background-image: url(../../../../assets/images/icons/vote-indicator.svg);
							background-size: contain;
							width: 3em;
							height: 3em;
						}
					}
				}

				.Vote-saveNotification
				{
					@include flex('', '', 'row');
					margin-left: 1em;

					.Vote-saving
					{
						@include flex('', 'center', 'row');
						padding-left: 1em;
				
						.Vote-savingTextWrapper
						{
							color: $text-light;
							font-size: 1.5em;
							font-weight: bold;
							.Vote-savingText
							{
								@include loadingFade();
								display: none;
							}
				
							.Vote-savedText
							{
								display: none;
							}
				
							.active 
							{
								display: block;
							}
					
						}
					}
				}
			}
		}
	}
}