@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameCode
{
	@include flex('center', '', 'row');
	position: relative;
	
	width: 43.4em;
	height: 6em;
	background-color: rgba($white, 0.6);
	border-radius: 0.66em;
	margin-bottom: 1em;

	.GameCode-icon {
		position: absolute;
		width: 2.8em;
		height: 2.8em;
		top: 0.9em;
		left: 1.5em;
		background-image: url(../../../assets/images/icons/info-icon.svg);
		background-size: contain;
	}
	.GameCode-code
	{
		@include flex('center', 'center', 'column');
		width: 100%;
		text-align: center;
		line-height: 2.22em;

		span
		{
			font-size: 1.66em;
			color: $darkBlue;
			font-weight: bold;
		}
	}

}