@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.FinalGroupScores
{
	position: relative;
	overflow: hidden;
	@include groupBackgroundColor();
	@include flex('space-between', '', 'column');

	height: 100%;
	width: 100%;
	padding: 1em;

	.FinalGroupScores-backgroundWrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.FinalGroupScores-header
	{
		@include flex('center', '', 'row');
		width: 100%;
		
		.FinalGroupScores-title
		{
			@include titleStyling();
		}
	}

	.FinalGroupScores-score
	{
		@include flex('', 'center', 'column');
		z-index: 2;
		margin-top: 2em;

		.FinalGroupScores-groupsWrapper
		{
			background-color: $transparentDarkBlue;
			box-shadow: 0.5em 0.5em $boxShadow;
			border-radius: 0.5em;
			padding: 1em;
			width: fit-content;
			
			.FinalGroupScores-scoreboardHeader
			{
				@include flex('', '', 'row');
				margin-bottom: 1em;
				span
				{
					color: $text-light;
					font-weight: bold;
					font-size: 1.5em;
				}

				.FinalGroupScores-scoreboardPlacement
				{
					width: 21em;
				}
				
				.FinalGroupScores-scoreboardPoint
				{
					width: 6.5em;
				}
			}

			.FinalGroupScores-groupPointsWrapper
			{
				background-color: white;
				padding: 1em;
				padding-top: 0.75em;
				padding-bottom: 0.25em;
				border-radius: 0.5em;
				box-shadow: 0.3em 0.3em $boxShadow;

				.insetShadow {
					box-shadow: 0 0.3em 0.5em $boxShadow inset;
				}

				.FinalGroupScores-groupPoints 
				{
					@include flex('', 'center', 'row');
					padding-bottom: 0.5em;

					.FinalGroupScores-groupPlacement 
					{
						@include flex('center', 'center');
						width: 2em;
						height: 2em;
						background-color: $darkBlue;
						border-radius: 0.25em;
						margin-right: 0.5em;

						span
						{
							color: $text-light;
							font-weight: bold;
						}
					}
					.FinalGroupScores-groupName 
					{
						@include flex('space-between', 'center');
						height: 2em;
						border-radius: 0.25em;
						width: 17em;
						padding-left: 1em;
						padding-right: 0.5em;
						margin-right: 0.5em;

						span
						{
							color: $text-light;
							font-weight: bold;
						}
					}
					.FinalGroupScores-groupGameTotal 
					{
						@include flex('center', 'center');
						height: 2em;
						width: 6em;
						border-radius: 0.25em;
						margin-right: 0.5em;
						
						span
						{
							color: $text-light;
							font-weight: bold;
							font-size: 1.5em;
						}
					}
					.FinalGroupScores-groupTrophy
					{
						border-radius: 0.25em;
						width: 3.5em;
						height: 2.5em;
						background-size: contain;
						background-origin: content-box;

						&.small
						{
							padding: 0.5em;
							background-image: url(../../../../assets/images/icons/trophy-small.svg);
						}
						&.large
						{
							padding: 0.25em;
							background-image: url(../../../../assets/images/icons/trophy-large.svg);
						}
					}
				}
			}
		}
	}

	.FinalGroupScores-navigation
	{
		@include flex('space-between', 'flex-end', 'row');
		z-index: 2;
		width: 100%;

		.FinalGroupScores-gameCode
		{
			@include flex('center');
			width: 33%;
		}
		.FinalGroupScores-navigationButtons
		{
			@include flex('', 'flex-start', 'column');
			width: 34%;
		}
	}
}