@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupBox
{
	@include groupBackgroundColor();

	width: 100%;
	height: 100%;

	.GroupBox-groupWrapper
	{
		@include flex('center', 'center', 'column');
		width: 100%;
		height: 100%;

		.GroupBox-groupBox
		{
			background-color: $offWhite;
			width: 100%;
			height: 100%;
			border-radius: 0.5em;
			padding: 0.4em;
			box-shadow: 0.5em 0.5em $boxShadow;

			&.clicked
			{
				@include transition('margin-top');
				margin-top: -2em;
				margin-bottom: 2em;
			}

			&.clickable
			{
				cursor: pointer;
			}

			.GroupBox-groupLogo
			{
				border-top-left-radius: 0.5em;
				border-top-right-radius: 0.5em;
				width: 100%;
				height: 75%;
				padding: 0.5em;
				background-origin: content-box;
				background-size: contain;

				&.groupLogo1 {
					background-image: url(../../../assets/images/icons/group-1-logo.svg);
				}
				&.groupLogo2 {
					background-image: url(../../../assets/images/icons/group-2-logo.svg);
				}
				&.groupLogo3 {
					background-image: url(../../../assets/images/icons/group-3-logo.svg);
				}
				&.groupLogo4 {
					background-image: url(../../../assets/images/icons/group-4-logo.svg);
				}
				&.groupLogo5 {
					background-image: url(../../../assets/images/icons/group-5-logo.svg);
				}
				&.groupLogo6 {
					background-image: url(../../../assets/images/icons/group-6-logo.svg);
				}
			}
			.GroupBox-groupName
			{
				@include flex('center', 'center');
				padding: 0.5em;
				height: 25%;
				span
				{
					font-size: 0.9em;
					font-weight: bold;
				}
			}
		}

		.GroupBox-groupReady 
		{
			@include flex('center', 'center');
			border-radius: 0.75em;
			width: 9em;
			height: 3em;
			margin-top: -0.5em;
			background-color: rgba($darkBlue, 0.6);
			
			&.active
			{
				background-color: $groupReadyGreen;
			}

			&.fadeOut
			{
				@include transition(1s, 'opacity');
				opacity: 0;
			}

			span
			{
				color: $text-light;
				font-weight: 500;
				font-size: 1.25em;
				text-transform: uppercase;
			}
		}
	}
}