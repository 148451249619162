@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Frontpage {
	@include flex('space-between', 'center', 'column');
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.Frontpage-logoCGLWrapper {
		position: absolute;
		top: 1em;
		right: 1em;

		.Frontpage-logoCGL
		{
			cursor: pointer;
			background-image: url(../../assets/images/logos/cphgamelab_Logo.svg);
			background-size: contain;
			width: 7em;
			height: 1.5em;
			z-index: 3;
		}
	}

	.Frontpage-backgroundWrapper {
		position: absolute;
		@include flex('space-between', 'center', 'column');
		width: 100%;
		height: 100%;

		.Frontpage-logo
		{
			position: relative;
			height: auto;
			width: 50%;

			top: 2em;

			#area1 {
				@include svgFloatUpAndDown(4s, 0s);
			}
			#area2 {
				@include svgFloatUpAndDown(4s, 0.4s);
			}
			#area3 {
				@include svgFloatUpAndDown(4s, 0.8s);
			}
		}
		.Frontpage-background
		{
			position: relative;
			height: auto;
			width: 100%;
			z-index: 1;

			bottom: -12em;

			#area1 {
				@include svgFloatUpAndDown(4s, 0s);
			}
			#area2 {
				@include svgFloatUpAndDown(4s, 0.4s);
			}
			#area3 {
				@include svgFloatUpAndDown(4s, 0.8s);
			}
		}
	}

	.Frontpage-sisLogo {
		position: absolute;
		background-image: url(../../assets/images/logos/Skolen_i_skoven.png);
		background-size: contain;
		width: 20em;
		height: 9.5em;
		top: -2em;
		left: -2em;
	}

	.Frontpage-logos
	{
		@include flex('space-between', 'center');
		position: relative;
		z-index: 3;
		width: 100%;

		background-color: rgba($offWhite, 0.75);

		margin-bottom: 1em;
		padding: 0.5em;
		padding-left: 5em;
		padding-right: 5em;

		.Frontpage-logoTitle {
			position: absolute;
			top: 1em;
			left: 1em;
			span {
				font-weight: bold;
			}
		}

		.Frontpage-logoBDK {
			text-align: center;
			font-weight: 500;
		}
		
		.Frontpage-logoNFF
		{
			background-image: url(../../assets/images/logos/danmarks-naturfredningsforening.svg);
			background-size: contain;
			width: 9em;
			height: 5em;
		}
		.Frontpage-logoFR
		{
			background-image: url(../../assets/images/logos/friluftsrådet.svg);
			background-size: contain;
			width: 10em;
			height: 5em;
		}
		.Frontpage-logoJF
		{
			background-image: url(../../assets/images/logos/jægerforbund.png);
			background-size: contain;
			width: 10em;
			height: 5em;
		}
		.Frontpage-logoLF
		{
			background-image: url(../../assets/images/logos/landbrug-og-fødevarer.svg);
			background-size: contain;
			width: 7em;
			height: 5em;
		}
		.Frontpage-logoSFF
		{
			background-image: url(../../assets/images/logos/sportsfiskerforbundet.svg);
			background-size: contain;
			width: 11em;
			height: 5em;
		}
		.Frontpage-logoSkjoldungernesLand {
			background-image: url(../../assets/images/logos/skjoldungernesland.png);
			background-size: contain;
			width: 13em;
			height: 3em;
		}
	}
}