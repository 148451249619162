@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn-area {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes yellowColorFadeIn {
	0% {color: rgba($yellow, 0);}
	100% {color: rgba($yellow, 1);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

@keyframes fadeInOut {
	0% {@include opacity(0);}
	50% {@include opacity(1);}
	100% {@include opacity(0);}
}

@keyframes pointFloat {
	0% {
		@include opacity(0);
	}
	25% {
		transform: translateY(-3em) scale(1.5);
		@include opacity(1);
	}
	100% {
		transform: translateY(-8em) scale(2);
		@include opacity(0);
	}
}

@keyframes translateYScale {
	0% {
		transform: translateY(0em);
	}
	65% {
		transform: translateY(-7em);
	}
	100% {
		transform: translateY(-3em);
	}
}

@keyframes translateRightFade {
	0% {
		transform: translateX(0em) scale(1);
		@include opacity(0);
	}
	10% {
		transform: translateX(0.5em) scale(2);
		@include opacity(1);
	}
	50% {
		transform: translateX(2.5em) scale(2);
		@include opacity(1);
	}
	100% {
		transform: translateX(5em) scale(0);
		@include opacity(0);
	}
}

@keyframes floatRight {
	0% {left: -20em;}
	100% {left: 120%;}
}

@keyframes floatUpAndDown {
	0% 		{transform: translateY(0%)}
	50% 	{transform: translateY(1%)}
	100% 	{transform: translateY(0%)}
}

@keyframes arrowFloat {
	0% 		{transform: translateY(0%) scaleY(1)}
	25% 	{transform: translateY(30%) scaleY(0.90)}
	100% 	{transform: translateY(0%) scaleY(1)}
}

@keyframes pointBarGrow {
	0% {
		opacity: 1;
		width: 0%;
	}
	100% {
		opacity: 1;
		width: 100%;
	}
}