@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupHint {
	position: relative;
	z-index: 3;
	
	.GroupHint-closeIcon {
		cursor: pointer;
		background-image: url('../../../assets/images/icons/cross.svg');
		background-size: 80% auto;
		width: 1.5em;
		height: 1.5em;
	}

	.GroupHint-expandIconUp {
		cursor: pointer;
		background-image: url('../../../assets/images/icons/arrow-up.svg');
		background-position: top right;
		background-size: cover;
		width: 1.5em;
		height: 1.5em;
	}

	.GroupHint-groupHint
	{
		height: 100%;

		.GroupHint-groupHintBox
		{
			position: fixed;
			bottom: 0;
			right: 10em;
			width: 20em;

			.GroupHint-groupHintTitle
			{
				@include noHighlight();
				@include flex('space-between', 'center', 'row');
				border-top-left-radius: 0.5em;
				border-top-right-radius: 0.5em;
				padding: 0.5em;
				padding-left: 1.5em;
				color: $text-light;

				span {
					font-weight: bold;
				}
			}

			.GroupHint-groupHintText
			{
				height: 0em;
				background-color: rgba($white, 0.8);

				.GroupHint-groupHintLine {
					padding-top: 0.5em;
					padding-bottom: 0.5em;
					padding-right: 0.5em;
				}

				&.active {
					height: fit-content;
				}
			}
		}
	}
}