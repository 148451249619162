@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.VoteResult
{
	height: 100%;
	padding: 1em;

	@include groupBackgroundColor();

	.VoteResult-headerWrapper 
	{
		@include flex('space-between', '', 'row');
		width: 100%;
		height: 5%;
		margin-bottom: 1em;
		color: $text-light;
		z-index: 2;

		.VoteResult-filler {
			width: 33%;
		}
		.VoteResult-titleWrapper 
		{
			width: 33%;
			@include titleStyling();
		}
		.VoteResult-rulesButton {
			@include flex('flex-end', 'flex-start');
			width: 33%;
			z-index: 2;
		}
	}

	.VoteResult-bodyWrapper 
	{
		@include flex('space-between','','column');
		width: 100%;
		height: 95%;
		padding-bottom: 1em;

		.VoteResult-resultWrapper
		{
			z-index: 2;

			.VoteResult-subAreas
			{
				@include flex('flex-start','','row');
				width: 100%;
				
				.VoteResult-subArea
				{
					width: 22%;
					.VoteResult-subAreaTitleWrapper
					{
						@include flex('flex-start');
						text-align: center;
						margin-bottom: 0.25em;
						.VoteResult-subAreaTitle
						{
							padding: 0.5em;
							border-radius: 0.6em;
							color: $text-light;

							span
							{
								font-weight: bold;
								font-size: 1.2em;
							}
						}
					}

					.VoteResult-actions
					{
						@include flex('','','column');
						background-color: rgba($darkBlue, 0.4);
						padding: 0.5em;
						height: fit-content;
						border-radius: 0.5em;

						.VoteResult-actionWrapper
						{
							background-color: rgba(#000, 0.5);
							height: 6.5em;
							border-radius: 0.75em;
							padding: 0.2em;
							margin-bottom: 0.5em;

							.VoteResult-action
							{
								height: 100%;
								border-radius: 0.6em;

								&.topVote
								{
									@include borderFadeIn(1s, 'ease', 0s);
									border: solid;
									border-width: 0.15em;
								}

								.VoteResult-actionHeader
								{
									@include flex('space-between', '', 'row');
									padding: 0.5em;
									padding-bottom: 0em;

									.VoteResult-title
									{
										@include flex('', '', 'row');
										span
										{
											color: $text-light;
											font-weight: bold;
										}

										.VoteResult-isExpensive
										{
											background-image: url(../../../../assets/images/icons/expensive.svg);
											background-size: contain;
											width: 2.5em;
											height: 1.5em;
											margin-top: -0.2em;
											margin-left: 0.5em;
										}
									}

									.VoteResult-checkmark
									{
										@include fadeIn(1s, 'ease', 0s);
										background-image: url(../../../../assets/images/icons/checkmark.svg);
										background-size: contain;
										width: 1.5em;
										height: 1.5em;

										margin-top: -1.4em;
										margin-right: -1.3em;

										display: none;

										&.active
										{
											display: block;
										}
									}
								}

								.VoteResult-actionVotes
								{
									@include flex('', '', 'row');
									padding: 0.5em;
									padding-top: 0.5em;
									.VoteResult-groupVote
									{
										@include fadeIn(1s, 'ease', 0s);
										@include flex('center', 'center');
										width: 2.5em;
										height: 2.5em;
										border-radius: 0.25em;
										border-style: solid;
										color: $white;
										border-width: 0.2em;
										margin-right: 0.5em;

										span
										{
											font-size: 1.75em;
											font-weight: bold;
										}
									}

								}
							}
						}

						.VoteResult-voteResultsPointsWrapper 
						{
							background-color: rgba(#000, 0.5);
							border-radius: 0.5em;
							padding: 0.75em;

							.VoteResult-voteResultPointsTitle
							{
								color: $text-light;

								span {
									font-weight: bold;
								}
							}

							.VoteResult-voteResultsPoint 
							{
								@include flex('', '', 'row');
								margin-top: 0.5em;

								.VoteResult-PointName 
								{
									span {
										color: $text-light;
									}
								}
								.VoteResult-Point 
								{
									margin-left: 0.25em;
									span {
										color: $yellow;
									}
								}
							}
						}
					}
				}
			}
		}

		.VoteResult-navigation
		{
			@include flex('space-between', 'flex-end', 'row');
			z-index: 2;
			width: 100%;
	
			.VoteResult-gameCode
			{
				@include flex('center');
				width: 34%;
			}
			.VoteResult-navigationButtons
			{
				@include flex('', 'flex-start', 'column');
				width: 33%;
			}
		}
	}
}