@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.DebateInfo {
	height: 100%;
	width: 100%;

	padding: 1em;

	@include flex('', '', 'column');
	@include groupBackgroundColor();
	@include groupTextColor();

	.castShadow {
		box-shadow: 0.3em 0.3em $boxShadow;
	}
				
	.DebateInfo-triangle
	{
		@include flex('center');
		display: none;
		margin-left: 0.5em;
		margin-top: 0.25em;
		margin-bottom: -0.25em;
		background-image: url(../../../../assets/images/icons/arrow-right.svg);
		background-size: contain;
		width: 1.5em;
		height: 2em;

		&.active
		{
			display: block;
		}
	}

	.DebateInfo-contentWrapper
	{
		@include flex('', '', 'row');
		height: 100%;
		width: 100%;
		padding-bottom: 1em;
		z-index: 3;
		
		.DebateInfo-sidebar
		{
			z-index: 2;
			@include flex('', '', 'column');
			width: 33%;
		
			.DebateInfo-sidebarAreas
			{
				@include flex('', '', 'column');
				z-index: 2;
				width: 16em;

				.DebateInfo-sidebarDescriptionBox {
					width: 14em;
					border-radius: 0.5em;
					margin-bottom: 1em;
					background-color: $transparentDarkBlue;
					
					.DebateInfo-sidebarDescriptionTitle {
						border-bottom: solid;
						border-width: 0.2em;
						color: $darkBlue;
						padding: 0.5em;
						span {
							font-size: 1.5em;
							font-weight: bold;
							color: $text-light;
						}
					}

					.DebateInfo-sidebarDescriptionHeader {
						padding: 0.5em;
						.DebateInfo-sidebarDescriptionText {
							line-height: 1.4em;
							color: $text-light;
						}
					}
				}

				.DebateInfo-sidebarArea
				{
					@include flex('', '', 'row');
					width: 100%;
					margin-bottom: 1em;
					.DebateInfo-sidebarAreaName
					{
						cursor: pointer;
						background-color: $darkBlue;
						color: $text-light;
						font-weight: bold;
						width: 14em;

						border-radius: 0.5em;
						padding: 0.5em;
						padding-left: 1em;
					
						span {
							pointer-events: none;
						}
						
						&.active
						{
							background-color: $offWhite;
							color: $darkBlue;
						}
					}
					.DebateInfo-sidebarAreaName:first-letter
					{
						text-transform: capitalize;
					}

					.DebateInfo-description
					{
						background-color: $transparentDarkBlue;
						border-radius: 0.5em;
						margin-bottom: 0.5em;
						padding: 1em;

						span
						{
							color: $text-light;
							line-height: 1.25em;
						}
					}
					.DebateInfo-sidebarAreaDescription
					{
						position: absolute;
						display: none;
						width: 20em;
						top: 1em;
						left: 17em;

						.DebateInfo-descriptionTitle {
							font-weight: bold;
							color: $text-light;
							padding-bottom: 0.25em;
						}
						.DebateInfo-descriptionTitle:first-letter {
							text-transform: capitalize;
						}
						
						&.active
						{
							display: block;
						}
					}
				}
			}
		}

		.DebateInfo-titleWrapper {
			color: $text-light;
			@include flex('', 'center', 'column');
			width: 100%;
		}

		.DebateInfo-rulesButton {
			@include flex('flex-end', 'flex-start');
			width: 33%;
		}
	}

	.DebateInfo-navigation
	{
		@include flex('space-between', 'flex-end', 'row');
		z-index: 2;
		margin-top: -2em;

		.DebateInfo-navigationButtons
		{
			@include flex('', 'flex-start', 'column');
			width: 33%;
		}
	}
}