@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
	height: 100%;
	overflow: auto;
	background-color: $gradient1;
	background-image: linear-gradient(
		to top, 
		$gradient1,
		$gradient2 45%,
		$gradient3 55%,
		$gradient4 60%,
		$gradient5
	);
}